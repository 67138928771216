<template>
  <div>
    <div class="">
        <h5 class="mb-3">Have a comment to share?</h5>
        <vs-textarea class="w-full" rows="3" name="content" v-validate="{required: true, max:1000}" 
            counter="1000" label="Type your comment here" v-model="data.content" />
    </div>
    <div class="flex flex-wrap items-center justify-end">
        <vs-button type="filled" @click.prevent="create" class="mt-2 block">Send</vs-button>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        orderId:{
            type: Number,
            required: true
        },
        file:{
            type: Object,
            required: true
        }
    },
    data(){
        return{
            popupAttachment: false,
            data:{
                title:"File Comment",
                content:"",
                icon: "MessageSquareIcon",
                order_id: this.orderId,
                type: "ORDER_COMMENT",
                url: `/orders/${this.orderId}`,
                file: this.file 
            }
        }
    },
    methods:{
        create(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    const formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({ color: "#444", type: "sound"})
                    this.$http.post(`/activities/create`, formData)
                    .then((response) => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit("orderList/ADD_ACTIVITY", response.data.data.activity)
                            this.initializeAll();
                        }
                    })
                }
            })
        },
        initializeAll(){
            this.data.content = ""
            this.$validator.reset()
        }
    }
}
</script>

<style>

</style>