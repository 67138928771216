<template>
  <div class="order-show" id="order-view">
    <vs-alert color="danger" title="Order Not Found" :active.sync="order_not_found">
      <span>Order record with id: '{{ $route.params.orderId }}' not found. </span>
      <span>
        <span>Check </span><span @click="$router.push({path: '/orders'})" class="text-inherit underline">All Orders</span>
      </span>
    </vs-alert>
    <div v-if="!isLoading">
      <div class="vx-row">
        <div class="vx-col md:w-2/3 w-full">
          <vs-tabs :value="activeTab" position="top" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
              <vs-tab :label="!isSmallerScreen ? 'ACTIVITY' : 'ACTIVITY'">
                <div class="tab-activity mt-5 ml-0">
                  <order-sidebar-details v-if="isSmallerScreen" class="mb-3" />
                  <order-extra-service
                    class="mb-3"
                    v-if="['pending','delivered','revision'].includes(order.status)"
                    />
                  <order-activity class="mb-3" />
                </div>
              </vs-tab>
              <vs-tab :label="!isSmallerScreen ? 'DETAILS' : 'DETAILS'">
                <div class="tab-details mt-5 ml-0">
                    <order-overview class="mb-3" />
                </div>
              </vs-tab>
              <vs-tab :label="!isSmallerScreen ? 'REQUIREMENTS' : 'REQ'">
                <div class="tab-requirements mt-5 ml-0">
                  <order-requirements  class="mb-3" />
                </div>
              </vs-tab>
          </vs-tabs>
        </div>
        <div v-if="!isSmallerScreen" class="vx-col pt-12 md:w-1/3 hidden md:block w-full">
          <order-sidebar-details />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderActivity from "@/components/order/OrderActivity.vue"
import OrderOverview from "@/components/order/OrderOverview.vue"
import OrderRequirements from "@/components/order/OrderRequirements.vue"
import OrderSidebarDetails from "@/components/order/OrderSidebarDetails.vue"
import OrderExtraService from '@/components/order/OrderExtraService.vue'

export default {
  components: {
    OrderActivity,
    OrderOverview,
    OrderRequirements,
    OrderSidebarDetails,
    OrderExtraService
  },
  data() {
    return {
      isLoading: true,
      order_not_found: false,
      colorx: '#8B0000',
    }
  },
  watch: {
    "$route":"fetchOrder"
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    order(){
      return this.$store.state.orderList.order
    },
    activeTab(){
      return this.$route.query.tab
        ? +this.$route.query.tab
        : 0;
    },
  },
  methods:{
    fetchOrder(){
      this.isLoading = true
      this.$vs.loading({scale: 0.6});
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.isLoading = false
        }else{
          this.order_not_found = true
        }
      })
    },
  },
  created(){
    this.fetchOrder();
  },
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
.con-vs-tabs{
    .con-ul-tabs{
        .vs-icon{
            font-size:1.5rem;
        }
    }
}
.order-show .vs-tabs--li button.vs-tabs--btn{
  padding: 10px 17px;
}
</style>
